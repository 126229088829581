import React, { createContext, useState } from "react";
import { Button } from "components/Button";
import { FormController } from "app/lib/FormController";
import { Takeover } from "components/Takeover";
import { RadioButton } from "components/RadioButton";
import { OptionGroup } from "components/OptionGroup";
import { InputDropdown } from "components/InputDropdown";
import { CommitType } from "types/generated-graphql/__types__";
import { CreditType } from "app/types/credit-types";
import { DropdownItem } from "components/Dropdown";
import { CreateProductModal } from "app/pages/Contracts/Pricing/CreateAndEditProductModal";
import { useGetContractsQuery, useGetProductsQuery } from "./data.graphql";
import { ProductListItem } from "app/pages/Contracts/lib/ProductListItem";
import { useNow } from "lib/date";
import { ButtonGroup } from "components/ButtonGroup";
import { useRateCardQuery } from "app/pages/Contracts/Pricing/RateCardsDetails/data.graphql";
import { PrepaidCommitTerms } from "./PrepaidCommitTerms";
import { USD_CREDIT_ID, USD_CREDIT_TYPE } from "app/lib/credits";
import { Schema as PricingSchema } from "app/pages/Contracts/Pricing/Schema";
import { useFeatureFlag } from "app/lib/launchdarkly";
import { TextInput } from "components/Input";
import { PostpaidCommitTerms } from "./PostpaidCommitTerms";
import { isFiat } from "app/pages/Contracts/lib/CreditTypes";
import { Schema } from "../../Schema";
import { useConfirmClose } from "../../components/ConfirmClose";
import { useCommitFlyoverController } from "./CommitFlyoverController";
import { CommitPreview } from "./CommitPreview";
import { MultipleInvoicePreview } from "./MultipleInvoicePreview";
import { useRecurringCommitController } from "./RecurringCommitController";
import { RecurringCommitTerms } from "./RecurringCommitTerms";
import { CommitAdvancedTerms } from "./CommitAdvancedTerms";

const dummyProductId = "0ab465e4-cf01-4b70-ba31-7a377db38299";

interface CreditTypeContextType {
  fiatCreditTypes: CreditType[];
  customCreditTypes: CreditType[];
  rateCardFiatCreditType: CreditType;
  creditTypeConversions: PricingSchema.Types.CreditTypeConversion[];
}
export const CreditTypeContext = createContext<CreditTypeContextType>({
  fiatCreditTypes: [],
  customCreditTypes: [],
  rateCardFiatCreditType: USD_CREDIT_TYPE,
  creditTypeConversions: [],
});

interface Props {
  edit: Schema.Types.CommitFlyoverRoot | undefined;
  recurringCommitEdit: Schema.Types.RecurringCommitRoot | undefined;
  onSave?: (commit: Schema.Types.CommitFlyoverRoot) => void | Promise<void>;
  onRecurringSave?: (
    commit: Schema.Types.RecurringCommitRoot,
  ) => void | Promise<void>;
  onClose: () => void;
  onDelete?: () => void;
  options?: {
    netsuiteEnabled?: boolean;
    level?: "customer" | "contract";
    isAmendment?: boolean;
    asCredit?: boolean;
    customerId?: string;
  };
  fiatCreditTypes: CreditType[];
  customCreditTypes: CreditType[];
  defaultCreditType: CreditType;
  rateCardId?: string;
  isExistingCommit: boolean;
  isOpen: boolean;
}

export const CommitTakeover: React.FC<Props> = (props) => {
  const showCurrencyWork = useFeatureFlag<boolean>(
    "contract-currencies",
    false,
  );

  const isCredit = props.options?.asCredit ?? false;
  const commitOrCredit = isCredit ? "credit" : "commit";
  const level = props.options?.level ?? "contract";

  const [submitting, setSubmitting] = useState(false);
  const [isRecurring, setIsRecurring] = useState<boolean>(false);
  const [productSearch, setProductSearch] = useState<string>("");
  const [showNewProductModal, setShowNewProductModal] = useState(false);

  const singleCommitCtrl = useCommitFlyoverController(props.edit);
  const recurringCommitCtrl = useRecurringCommitController(
    props.recurringCommitEdit,
  );

  // Type is set to FormController<any> to satisfy the type for useConfirmClose
  const ctrl: FormController<any> = isRecurring
    ? recurringCommitCtrl
    : singleCommitCtrl;
  const [confirmingClose, confirmClose] = useConfirmClose(ctrl, props.onClose);

  async function singleCommitSubmit(valid: Schema.Types.CommitFlyoverRoot) {
    setSubmitting(true);
    if (props.onSave) {
      await props.onSave(valid);
    }
    setSubmitting(false);
  }

  async function recurringCommitSubmit(
    valid: Schema.Types.RecurringCommitRoot,
  ) {
    setSubmitting(true);
    if (props.onRecurringSave) {
      await props.onRecurringSave(valid);
    }
    setSubmitting(false);
  }

  // Hooks must be defined directly in the React component
  const singleCommitSubmitHandler = FormController.useSubmitHandler(
    singleCommitCtrl,
    async (valid) => {
      await singleCommitSubmit(valid);
      props.onClose();
    },
  );
  const singleCommitAddAnotherHandler = FormController.useSubmitHandler(
    singleCommitCtrl,
    async (valid) => {
      await singleCommitSubmit(valid);
      singleCommitCtrl.reset();
    },
  );
  const recurringCommitSubmitHandler = FormController.useSubmitHandler(
    recurringCommitCtrl,
    async (valid) => {
      await recurringCommitSubmit(valid);
      props.onClose();
    },
  );
  const recurringCommitAddAnotherHandler = FormController.useSubmitHandler(
    recurringCommitCtrl,
    async (valid) => {
      await recurringCommitSubmit(valid);
      recurringCommitCtrl.reset();
    },
  );
  function onSubmit(isAddingAnother: boolean = false) {
    if (isRecurring) {
      isAddingAnother
        ? recurringCommitAddAnotherHandler()
        : recurringCommitSubmitHandler();
    } else {
      isAddingAnother
        ? singleCommitAddAnotherHandler()
        : singleCommitSubmitHandler();
    }
  }

  const now = useNow();
  const contractsReq = useGetContractsQuery({
    variables: {
      customerId: props.options?.customerId ?? "",
    },
    skip: level !== "customer" || !props.options?.customerId,
  });
  const productsReq = useGetProductsQuery();
  const rateCardReq = useRateCardQuery({
    variables: { id: props.rateCardId ?? "" },
  });
  const rateCard = rateCardReq.data?.contract_pricing.rate_card;
  const rateCardFiatCreditType = rateCard?.fiat_credit_type
    ? {
        ...rateCard?.fiat_credit_type,
        client_id: null,
        environment_type: null,
      }
    : USD_CREDIT_TYPE;
  const creditTypeConversions =
    rateCard?.credit_type_conversions?.map((c) => ({
      custom_credit_type_id: c.custom_credit_type.id,
      custom_credit_type_name: c.custom_credit_type.name,
      fiat_per_custom_credit:
        rateCard?.fiat_credit_type.id === USD_CREDIT_ID
          ? Number(c.fiat_per_custom_credit) / 100.0
          : Number(c.fiat_per_custom_credit),
    })) ?? [];

  const allProducts = productsReq.data?.contract_pricing.products ?? [];
  const fixedProducts = allProducts
    .filter((product) => product.__typename === "FixedProductListItem")
    .map((product) => ({
      __typename: product.__typename,
      id: product.id,
      name: ProductListItem.getName(product, now),
    }));
  const nonFixedProducts = allProducts
    .filter((product) => product.__typename !== "FixedProductListItem")
    .map((product) => ({
      __typename: product.__typename,
      id: product.id,
      name: ProductListItem.getName(product, now),
    }));

  const filteredFixedProduct =
    productSearch === ""
      ? fixedProducts ?? []
      : fixedProducts.filter((product) =>
          product.name.toLowerCase().includes(productSearch.toLowerCase()),
        );
  const currentFixedProductName =
    fixedProducts.find((product) => product.id === ctrl.get("productId"))
      ?.name ?? undefined;
  const applicableProductNames = nonFixedProducts
    .filter((product) =>
      (ctrl.get("applicableProductIds") ?? []).includes(product.id),
    )
    .map((product) => product.name);
  return (
    <>
      {confirmingClose}
      {showNewProductModal && (
        <CreateProductModal
          enforceType="fixed"
          onClose={(newProductId) => {
            setShowNewProductModal(false);
            ctrl.update({
              productId: newProductId,
            });
          }}
        />
      )}
      <Takeover
        isOpen={props.isOpen}
        onClose={confirmClose}
        title={
          props.edit ? `Edit a ${commitOrCredit}` : `Add a ${commitOrCredit}`
        }
        headerButtons={[
          <Button
            key="docs"
            text="View docs"
            theme="secondary"
            leadingIcon="share03"
            linkTo="https://docs.metronome.com/pricing-packaging/apply-credits-commits/"
            isExternalLink
          />,
        ]}
        footerLeadingButton={
          <Button
            key="cancel"
            onClick={confirmClose}
            text="Back"
            theme="secondary"
            className="w-[128px] justify-center"
          />
        }
        footerTrailingButtons={[
          !props.edit && level !== "customer" ? (
            <Button
              key="addAnother"
              disabled={!ctrl.isValid() || submitting}
              text="Add another"
              theme="secondary"
              className="w-[128px] justify-center"
              onClick={() => {
                onSubmit(true);
              }}
            />
          ) : (
            <></>
          ),
          <Button
            key="save"
            disabled={!ctrl.isValid() || submitting}
            text={props.edit ? "Save" : "Add"}
            theme="primary"
            className="w-[128px] justify-center"
            onClick={() => onSubmit(false)}
          />,
        ]}
      >
        <div className="space-y-6 p-6">
          <h2 className="mb-4 text-lg font-semibold">Terms</h2>
          <div className="w-full">
            <OptionGroup
              className="w-full items-start items-stretch"
              size="narrow"
              layout="flex-row"
            >
              <RadioButton
                name={`${commitOrCredit}Type`}
                value="single"
                onChange={({ checked }) => {
                  setIsRecurring(!checked);
                }}
                label={`Issue a single ${commitOrCredit}`}
                supportingText="Issue on a specified date or customize a distinct access schedule."
                className="p-6 flex-1 rounded-lg border hover:bg-gray-50"
                checked={!isRecurring}
                disabled={!!props.edit}
              />
              <RadioButton
                name={`${commitOrCredit}Type`}
                value="recurring"
                onChange={({ checked }) => {
                  setIsRecurring(checked);
                }}
                label={`Issue a recurring ${commitOrCredit}`}
                supportingText={`Issue ${commitOrCredit}s automatically on a recurring schedule aligned to the usage statement frequency.`}
                className="p-6 flex-1 rounded-lg border hover:bg-gray-50"
                checked={isRecurring}
                disabled={!!props.edit || !!props.options?.isAmendment}
              />
            </OptionGroup>
          </div>
        </div>
        <div className="mt-20">
          <InputDropdown
            key="productId"
            value={currentFixedProductName}
            onChangeText={({ value }: { value: string }) => {
              ctrl.update({ productId: value });
              setProductSearch(value);
            }}
            label={`Select a ${commitOrCredit} type`}
            placeholder={`Enter name of ${commitOrCredit} type`}
            hintText={`Choose the ${commitOrCredit} type you want to use. This is the line item that will appear on the invoice.`}
            fullWidth
          >
            {[
              <DropdownItem
                label="Add a product"
                key={dummyProductId}
                selected={ctrl.get("productId") === dummyProductId}
                value={dummyProductId}
                onClick={() => {
                  setShowNewProductModal(true);
                }}
              />,
              ...filteredFixedProduct.map((product) => (
                <DropdownItem
                  label={product.name}
                  key={product.id}
                  selected={ctrl.get("productId") === product.id}
                  value={product.id}
                  onClick={({ value }) => {
                    ctrl.update({ productId: value });
                  }}
                />
              )),
            ]}
          </InputDropdown>
          {!isRecurring && commitOrCredit === "commit" && (
            <div className="mt-20">
              <ButtonGroup
                buttons={[
                  {
                    onClick: () => ctrl.update({ type: CommitType.Prepaid }),
                    text: "Prepaid",
                    isActive: ctrl.get("type") === CommitType.Prepaid,
                    disabled: props.isExistingCommit,
                  },
                  {
                    onClick: () => ctrl.update({ type: CommitType.Postpaid }),
                    text: "Postpaid",
                    isActive: ctrl.get("type") === CommitType.Postpaid,
                    disabled: props.isExistingCommit,
                  },
                ]}
              />
            </div>
          )}
          <div className="mt-20">
            <CreditTypeContext.Provider
              value={{
                fiatCreditTypes: showCurrencyWork
                  ? props.fiatCreditTypes
                  : [USD_CREDIT_TYPE],
                customCreditTypes: props.customCreditTypes,
                rateCardFiatCreditType: rateCardFiatCreditType,
                creditTypeConversions: creditTypeConversions,
              }}
            >
              {!isRecurring ? (
                singleCommitCtrl.get("type") === CommitType.Prepaid ? (
                  <PrepaidCommitTerms
                    parent={singleCommitCtrl}
                    level={level}
                    asCredit={isCredit}
                    defaultCreditType={props.defaultCreditType}
                    isExistingCommit={props.isExistingCommit}
                  />
                ) : singleCommitCtrl.get("type") === CommitType.Postpaid ? (
                  <PostpaidCommitTerms
                    parent={singleCommitCtrl}
                    level={level}
                    defaultCreditType={
                      isFiat(props.defaultCreditType)
                        ? props.defaultCreditType
                        : props.fiatCreditTypes[0]
                    }
                    contracts={contractsReq.data?.customer?.contracts}
                    isExistingCommit={props.isExistingCommit}
                  />
                ) : null
              ) : (
                <RecurringCommitTerms
                  parent={recurringCommitCtrl}
                  asCredit={isCredit}
                  defaultCreditType={
                    isFiat(props.defaultCreditType)
                      ? props.defaultCreditType
                      : props.fiatCreditTypes[0]
                  }
                />
              )}
            </CreditTypeContext.Provider>
          </div>
          <div className="mt-20">
            <TextInput
              label="Priority"
              type="number"
              helpText="Dictates the order that commits and credits are applied. Commits/credits with a lower priority will be applied first."
              hintText={`The order simultaneous ${commitOrCredit}s are consumed`}
              placeholder="Enter priority"
              disabled={props.isExistingCommit}
              value={
                ctrl.get("commit")?.priority !== undefined
                  ? (String(ctrl.get("commit")?.priority) as string)
                  : undefined
              }
              onChange={({ value }) => {
                ctrl.update({
                  commit: {
                    ...ctrl.get("commit"),
                    priority: value !== "" ? Number(value) : undefined,
                  },
                });
              }}
            />
          </div>
        </div>
        <div className="mt-32 space-y-xl">
          <CommitAdvancedTerms
            singleCommitCtrl={singleCommitCtrl}
            recurringCommitCtrl={recurringCommitCtrl}
            level={props.options?.level}
            isRecurring={isRecurring}
            asCredit={isCredit}
            commitOrCredit={commitOrCredit}
            isExistingCommit={props.isExistingCommit}
            allProducts={allProducts}
            applicableProductNames={applicableProductNames}
            nonFixedProducts={nonFixedProducts}
            tags={[
              ...new Set(
                allProducts.flatMap((product) =>
                  ProductListItem.getTags(product, now),
                ),
              ),
            ].sort()}
          />
        </div>
        <CreditTypeContext.Provider
          value={{
            fiatCreditTypes: showCurrencyWork
              ? props.fiatCreditTypes
              : [USD_CREDIT_TYPE],
            customCreditTypes: props.customCreditTypes,
            rateCardFiatCreditType: rateCardFiatCreditType,
            creditTypeConversions: creditTypeConversions,
          }}
        >
          <div className="mt-32 pb-24">
            <CommitPreview
              ctrl={isRecurring ? recurringCommitCtrl : singleCommitCtrl}
              isRecurring={isRecurring}
              level={level}
              productName={currentFixedProductName}
              applicableProductNames={applicableProductNames}
              asCredit={isCredit}
            />
          </div>
          {!isRecurring &&
            !isCredit &&
            ctrl.get("type") === CommitType.Prepaid && (
              <div className="pb-24">
                <MultipleInvoicePreview
                  parent={singleCommitCtrl}
                  level={level}
                  defaultCreditType={props.defaultCreditType}
                />
              </div>
            )}
        </CreditTypeContext.Provider>
      </Takeover>
    </>
  );
};
