import {
  RoundedCurrency,
  USD_CREDIT_TYPE,
  creditsFromInput,
} from "lib/credits";
import Decimal from "decimal.js";
import React from "react";
import { Icon, NumericInput, Tooltip } from "design-system";
import { IconButton } from "tenaissance/components/IconButton";
import { CreditType } from "types/credit-types";
import { SimpleTable } from "components/SimpleTable";
import {
  createContractLineItemPointer,
  lineItemPointerToString,
} from "pages/CorrectInvoice/pointers";
import {
  InvoiceCorrectionItem,
  InvoiceCorrectionItemTypeEnum,
} from "types/generated-graphql/__types__";
import {
  ContractCorrectableLineItem,
  CorrectionEditMode,
} from "pages/CorrectInvoice/types";
import { Quantity } from "components/Invoice/components/LineItem/Quantity";

function tryAdd(
  a: Decimal | string | undefined,
  b: Decimal | string | undefined,
) {
  if (a == null || b == null) {
    return undefined;
  }
  return new Decimal(a).add(new Decimal(b));
}

export const ContractCorrectionTable: React.FC<{
  contractLineItemsAndCorrections: {
    originalLineItem?: ContractCorrectableLineItem;
    correctedLineItem?: ContractCorrectableLineItem;
  }[];
  corrections: Record<string, InvoiceCorrectionItem & { input?: number }>;
  editMode: CorrectionEditMode;
  onCorrectionsChanged: (
    corrections: Record<string, InvoiceCorrectionItem & { input?: number }>,
  ) => void;
  onEditModeChanged: (editMode: CorrectionEditMode) => void;
}> = ({
  contractLineItemsAndCorrections,
  corrections,
  editMode,
  onCorrectionsChanged,
  onEditModeChanged,
}) => {
  const changeEditMode = (newEditMode: CorrectionEditMode) => {
    if (newEditMode !== editMode) {
      onEditModeChanged(newEditMode);
      onCorrectionsChanged({});
    }
  };

  const renderEditableDiff = (
    targetEditMode: CorrectionEditMode,
    originalLineItem?: ContractCorrectableLineItem,
    rowValueRaw?: string | Decimal,
    creditType?: CreditType,
  ) => {
    if (targetEditMode === editMode && originalLineItem) {
      const isCompositeProduct =
        originalLineItem.__typename === "ContractUsageLineItem" &&
        originalLineItem?.product?.__typename === "CompositeProductListItem";
      const isResellerRoyalty =
        originalLineItem.__typename === "ContractAWSRoyaltyLineItem" ||
        originalLineItem.__typename === "ContractGCPRoyaltyLineItem";
      const isUneditableLineItem = isCompositeProduct || isResellerRoyalty;

      if (!isUneditableLineItem) {
        const lineItemPointer = createContractLineItemPointer(originalLineItem);
        const key = lineItemPointerToString(lineItemPointer);
        return (
          <NumericInput
            placeholder="123"
            size="regular"
            value={corrections[key]?.input}
            onChange={(v) => {
              const newCorrections = { ...corrections };
              if (v == null) {
                delete newCorrections[key];
              } else {
                let value: Decimal;
                let type: InvoiceCorrectionItemTypeEnum;
                if (editMode === "newTotal") {
                  value = creditsFromInput(v, creditType ?? USD_CREDIT_TYPE);
                  type = InvoiceCorrectionItemTypeEnum.TotalChange;
                } else if (editMode === "newQuantity") {
                  value = new Decimal(v);
                  type = InvoiceCorrectionItemTypeEnum.QuantityChange;
                } else if (editMode === "deltaTotal") {
                  value = new Decimal(originalLineItem.total).add(
                    creditsFromInput(v, creditType ?? USD_CREDIT_TYPE),
                  );
                  type = InvoiceCorrectionItemTypeEnum.TotalChange;
                } else if (editMode === "deltaQuantity") {
                  value = new Decimal(originalLineItem.quantity).add(v);
                  type = InvoiceCorrectionItemTypeEnum.QuantityChange;
                } else {
                  editMode satisfies never;
                  throw new Error(`Invalid edit mode ${editMode}`);
                }
                newCorrections[key] = {
                  value: value.toString(),
                  type,
                  contract_line_item_pointer: lineItemPointer,
                  input: v,
                };
              }
              onCorrectionsChanged(newCorrections);
            }}
          />
        );
      }
    }

    if (!rowValueRaw) {
      return "--";
    }

    const rowValue = new Decimal(rowValueRaw);
    if (creditType) {
      return <RoundedCurrency amount={rowValue} creditType={creditType} />;
    } else {
      const rounded = rowValue.abs().lessThan(1e-10)
        ? new Decimal(0)
        : rowValue.abs().lessThan(1)
          ? rowValue.toSignificantDigits(2)
          : rowValue.toDecimalPlaces(2);

      if (rounded.toString() !== rowValue.toString()) {
        return (
          <Tooltip inline content={rowValue.toString()}>
            {rounded.toString()}
          </Tooltip>
        );
      } else {
        return rowValue.toString();
      }
    }
  };

  const renderSelectableColumn = (
    name: string,
    targetEditMode: CorrectionEditMode,
  ) => {
    return (
      <span className="flex items-center">
        <IconButton
          disabled={targetEditMode === editMode}
          onClick={() => changeEditMode(targetEditMode)}
          theme="tertiary"
          icon="edit05"
        />
        {name}
      </span>
    );
  };

  return (
    <SimpleTable
      data={contractLineItemsAndCorrections.map((li) => ({
        ...li,
        pointer: li.originalLineItem
          ? createContractLineItemPointer(li.originalLineItem)
          : null,
      }))}
      columns={[
        {
          header: "Product",
          render: ({ originalLineItem, correctedLineItem }) =>
            originalLineItem?.display_name ?? correctedLineItem?.display_name,
        },
        {
          header: "Quantity",
          render: ({ originalLineItem }) => {
            if (!originalLineItem) {
              return "--";
            }

            if (
              (originalLineItem.__typename === "ContractUsageLineItem" &&
                originalLineItem.product.__typename ===
                  "CompositeProductListItem") ||
              originalLineItem.__typename === "ContractAWSRoyaltyLineItem" ||
              originalLineItem.__typename === "ContractGCPRoyaltyLineItem"
            ) {
              return (
                <RoundedCurrency
                  amount={new Decimal(originalLineItem.quantity)}
                  creditType={originalLineItem.credit_type}
                />
              );
            } else if ("quantity" in originalLineItem) {
              return <Quantity quantity={originalLineItem.quantity} rounded />;
            } else {
              return "-";
            }
          },
        },
        {
          header: "Unit price",
          render: ({ originalLineItem }) => {
            if (!originalLineItem) {
              return "--";
            }

            if (
              (originalLineItem.__typename === "ContractUsageLineItem" &&
                originalLineItem.product.__typename ===
                  "CompositeProductListItem") ||
              originalLineItem.__typename === "ContractAWSRoyaltyLineItem" ||
              originalLineItem.__typename === "ContractGCPRoyaltyLineItem"
            ) {
              return `${new Decimal(originalLineItem.unit_price).mul(100)}%`;
            } else if ("unit_price" in originalLineItem) {
              return (
                <RoundedCurrency
                  creditType={originalLineItem.credit_type}
                  amount={new Decimal(originalLineItem.unit_price)}
                />
              );
            }

            return "-";
          },
        },
        {
          header: "Total",
          render: ({ originalLineItem }) =>
            originalLineItem?.total ? (
              <RoundedCurrency
                creditType={originalLineItem.credit_type}
                amount={new Decimal(originalLineItem.total)}
              />
            ) : (
              "--"
            ),
        },
        {
          header: "",
          render: (row) => <Icon icon="arrowForward" />,
        },
        {
          header: renderSelectableColumn("New quantity", "newQuantity"),
          render: (r) =>
            renderEditableDiff(
              "newQuantity",
              r.originalLineItem,
              tryAdd(
                r.originalLineItem?.quantity,
                r.correctedLineItem?.quantity,
              ),
            ),
        },
        {
          header: renderSelectableColumn("New total", "newTotal"),
          render: (r) =>
            renderEditableDiff(
              "newTotal",
              r.originalLineItem,
              tryAdd(r.originalLineItem?.total, r.correctedLineItem?.total),
              r.correctedLineItem?.credit_type,
            ),
        },
        {
          header: renderSelectableColumn("Delta quantity", "deltaQuantity"),
          render: (r) =>
            renderEditableDiff(
              "deltaQuantity",
              r.originalLineItem,
              r.correctedLineItem?.quantity,
            ),
        },
        {
          header: renderSelectableColumn("Delta total", "deltaTotal"),
          render: (r) =>
            renderEditableDiff(
              "deltaTotal",
              r.originalLineItem,
              r.correctedLineItem?.total,
              r.correctedLineItem?.credit_type,
            ),
        },
      ]}
    />
  );
};
