import React, { useMemo, useState } from "react";
import { Button } from "components/Button";
import { CommitFlyoverController } from "./CommitFlyoverController";
import { RecurringCommitController } from "./RecurringCommitController";
import { TextInput } from "components/Input";
import { OptionGroup } from "components/OptionGroup";
import { DatePicker } from "components/DatePicker";
import {
  ContractUsageInvoiceScheduleFrequencyEnum,
  RecurringCommitDurationUnit,
} from "types/generated-graphql/__types__";
import { ButtonGroup } from "components/ButtonGroup";
import { Checkbox } from "components/Checkbox";
import { InputDropdown } from "components/InputDropdown";
import { DropdownItem } from "components/Dropdown";
import { DefaultTimeframe } from "../../lib/DefaultTimeframe";
import { dayjs } from "lib/dayjs";
import { ProductListItem } from "app/pages/Contracts/lib/ProductListItem";

// Maps the recurrence frequencies to user friendly strings
export const FREQUENCY_MAPPING: Record<
  ContractUsageInvoiceScheduleFrequencyEnum,
  string
> = {
  [ContractUsageInvoiceScheduleFrequencyEnum.Weekly]: "Weekly",
  [ContractUsageInvoiceScheduleFrequencyEnum.Monthly]: "Monthly",
  [ContractUsageInvoiceScheduleFrequencyEnum.Quarterly]: "Quarterly",
  [ContractUsageInvoiceScheduleFrequencyEnum.Annual]: "Annual",
};

type NonFixedProduct = {
  __typename: string;
  id: string;
  name: string;
};

type CommitAdvancedTermsProps = {
  /** Form controller for single (non-recurring) commits */
  singleCommitCtrl: CommitFlyoverController;
  /** Form controller for recurring commits */
  recurringCommitCtrl: RecurringCommitController;
  level?: "contract" | "customer";
  isRecurring: boolean;
  asCredit?: boolean;
  commitOrCredit: string;
  isExistingCommit: boolean;
  allProducts: Array<
    ProductListItem.IdFragment &
      ProductListItem.NameFragment &
      ProductListItem.TypeFragment
  >;
  nonFixedProducts: Array<{
    __typename: string;
    id: string;
    name: string;
  }>;
  applicableProductNames: string[];
  tags: string[];
};

export const CommitAdvancedTerms: React.FC<CommitAdvancedTermsProps> = (
  props,
) => {
  const [isAdvancedOpen, setIsAdvancedOpen] = useState<boolean>(false);
  const [isRolloverChecked, setIsRolloverChecked] = useState<boolean>(false);
  const [applicableProductSearch, setApplicableProductSearch] =
    useState<string>("");
  const [applicableTagSearch, setApplicableTagSearch] = useState<string>("");
  const [isCustomExpirationChecked, setIsCustomExpirationChecked] =
    useState<boolean>(false);
  const [isCustomRecurringChecked, setIsCustomRecurringChecked] =
    useState<boolean>(false);

  const isRecurring = props.isRecurring;
  const recurringCommitCtrl = props.recurringCommitCtrl;
  const singleCommitCtrl = props.singleCommitCtrl;
  const commitOrCredit = props.commitOrCredit;
  const applicableProductNames = props.applicableProductNames;
  const nonFixedProducts = props.nonFixedProducts;
  const tags = props.tags;

  const ctrl = isRecurring ? recurringCommitCtrl : singleCommitCtrl;

  const filteredNonFixedProducts = useMemo(() => {
    return nonFixedProducts.filter((p) =>
      p.name.toLowerCase().startsWith(applicableProductSearch.toLowerCase()),
    );
  }, [nonFixedProducts, applicableProductSearch]);
  function onApplicableProductsChange(newProductNames: string[]) {
    const prevApplicableProductIds = ctrl.get("applicableProductIds") ?? [];
    ctrl.update({
      applicableProductIds: nonFixedProducts
        .filter(
          (p) =>
            newProductNames.includes(p.name) &&
            prevApplicableProductIds.includes(p.id),
        )
        .map((p) => p.id),
    });
  }

  const filterTags = useMemo(() => {
    return tags.filter((t) =>
      t.toLowerCase().startsWith(applicableTagSearch.toLowerCase()),
    );
  }, [tags, applicableTagSearch]);
  function onApplicableTagsChange(newTags: string[]) {
    const prevTags = ctrl.get("applicableProductTags") ?? [];
    ctrl.update({
      applicableProductTags: tags.filter(
        (t) => newTags.includes(t) && prevTags.includes(t),
      ),
    });
  }

  const rolloverFraction = ctrl.get("commit")?.rolloverFraction;
  const onRolloverCheckboxClick = (checked: boolean) => {
    if (!checked) {
      ctrl.update({
        rolloverFraction: undefined,
        commit: {
          ...ctrl.get("commit"),
          rolloverFraction: undefined,
        },
      });
    }
    setIsRolloverChecked(checked);
  };
  function onRolloverChange(value: string) {
    // If the user types a decimal point followed by any number of zeroes,
    // avoid calling onPriceChange, as doing so would change the value to be
    // "0" (the decimal point would be lost).
    if (/^\.0+$/.test(value)) {
      return;
    }
    ctrl.update({
      rolloverFraction: value,
      commit: {
        ...ctrl.get("commit"),
        rolloverFraction: value !== "" ? Number(value) : undefined,
      },
    });
  }

  const periods = recurringCommitCtrl.get("commit")?.commitDuration.value;
  const onCustomExpirationCheckboxClick = (checked: boolean) => {
    if (!checked) {
      recurringCommitCtrl.update({
        commit: {
          ...recurringCommitCtrl.get("commit"),
          commitDuration: {
            unit: RecurringCommitDurationUnit.Periods,
            value: 1,
          },
        },
      });
    }
    setIsCustomExpirationChecked(checked);
  };

  const timeframe = DefaultTimeframe.useFromContext();
  const onCustomRecurringCheckboxClick = (checked: boolean) => {
    if (!checked) {
      recurringCommitCtrl.update({
        commit: {
          ...recurringCommitCtrl.get("commit"),
          recurrenceFrequency:
            ContractUsageInvoiceScheduleFrequencyEnum.Monthly,
          startingAt: timeframe.startingAt,
          endingBefore: undefined,
        },
      });
    }
    setIsCustomRecurringChecked(checked);
  };
  const recurringStartingAt = recurringCommitCtrl.get("commit")?.startingAt;
  const recurringEndingBefore = recurringCommitCtrl.get("commit")?.endingBefore;

  return (
    <>
      <Button
        text="Advanced"
        leadingIcon={isAdvancedOpen ? "chevronDown" : "chevronRight"}
        theme="tertiary"
        size="sm"
        onClick={() => setIsAdvancedOpen(!isAdvancedOpen)}
      />
      {isAdvancedOpen && (
        <>
          <div className="flex flex-col gap-[24px]">
            <div className="flex flex-row space-x-lg">
              <div className="flex flex-none flex-col">
                <TextInput
                  label="Name"
                  value={ctrl.get("name")}
                  isInvalid={!ctrl.isValid("name")}
                  helpText={`Name for ${commitOrCredit}`}
                  hintText={`Override the default name defined by the ${commitOrCredit} type`}
                  placeholder={ctrl.get("name") ? "" : "Enter name"}
                  onChange={({ value }) => ctrl.update({ name: value })}
                  disabled={props.isExistingCommit}
                />
              </div>
              <div className="flex flex-1 flex-col">
                <TextInput
                  label="Description"
                  value={ctrl.get("description")}
                  isInvalid={!ctrl.isValid("description")}
                  helpText={`Internal description for the ${commitOrCredit}`}
                  hintText="Add an internal description"
                  placeholder={
                    ctrl.get("description") ? "" : "Enter description"
                  }
                  onChange={({ value }) => ctrl.update({ description: value })}
                  disabled={props.isExistingCommit}
                  fullWidth
                />
              </div>
            </div>
            <TextInput
              label="NetSuite sales order ID"
              value={ctrl.get("netsuiteSalesOrderId")}
              isInvalid={!ctrl.isValid("netsuiteSalesOrderId")}
              helpText={`Associate a NetSuite sales order ID with the ${commitOrCredit}`}
              placeholder={
                ctrl.get("netsuiteSalesOrderId")
                  ? ""
                  : "Enter NetSuite sales order ID"
              }
              onChange={({ value }) =>
                ctrl.update({ netsuiteSalesOrderId: value })
              }
              fullWidth
            />
            <InputDropdown
              key="applicableProductId"
              value={applicableProductNames}
              onChangeTags={(meta: { value: string[] }) => {
                onApplicableProductsChange(meta.value);
              }}
              onUnenteredTagChange={({ value }) => {
                setApplicableProductSearch(value);
              }}
              tagsVariant={true}
              label="Target applicable products"
              placeholder="Enter individual products"
              hintText={`Choose specific products this ${commitOrCredit} can be spent on. Defaults to all products.`}
              fullWidth
            >
              {[
                ...(filteredNonFixedProducts?.map(
                  (product: NonFixedProduct) => (
                    <DropdownItem
                      label={product.name}
                      key={product.id}
                      value={product.id}
                      onClick={({ selected }) => {
                        const prevApplicableProductIds =
                          ctrl.get("applicableProductIds") ?? [];
                        selected
                          ? ctrl.update({
                              applicableProductIds:
                                prevApplicableProductIds.filter(
                                  (t: string) => t !== product.id,
                                ),
                            })
                          : ctrl.update({
                              applicableProductIds: [
                                ...prevApplicableProductIds,
                                product.id,
                              ],
                            });
                        setApplicableProductSearch("");
                      }}
                    />
                  ),
                ) || []),
              ]}
            </InputDropdown>
            <InputDropdown
              key="productId"
              value={ctrl.get("applicableProductTags") ?? []}
              onChangeTags={(meta: { value: string[] }) => {
                onApplicableTagsChange(meta.value);
              }}
              onUnenteredTagChange={({ value }) => {
                setApplicableTagSearch(value);
              }}
              tagsVariant={true}
              label="Target product tags"
              placeholder="Enter individual tags"
              hintText={`Choose specific product tags this ${commitOrCredit} can be spent on. Defaults to all products.`}
              fullWidth
            >
              {[
                ...(filterTags?.map((tag) => (
                  <DropdownItem
                    label={tag}
                    key={tag}
                    value={tag}
                    onClick={({ selected }) => {
                      const prevApplicableProductTags =
                        ctrl.get("applicableProductTags") ?? [];
                      selected
                        ? ctrl.update({
                            applicableProductTags:
                              prevApplicableProductTags.filter(
                                (t: string) => t !== tag,
                              ),
                          })
                        : ctrl.update({
                            applicableProductTags: [
                              ...prevApplicableProductTags,
                              tag,
                            ],
                          });
                      setApplicableTagSearch("");
                    }}
                  />
                )) || []),
              ]}
            </InputDropdown>

            {props?.level === "contract" && (
              <OptionGroup
                className="w-full items-stretch gap-lg"
                size="narrow"
              >
                {isRecurring && (
                  <>
                    <Checkbox
                      label="Add custom expiration schedule"
                      supportingText={`By default unused ${commitOrCredit}s expire at the end of the period. Select to rollover ${commitOrCredit}s for specified period of time.`}
                      checked={isCustomExpirationChecked}
                      onClick={({ checked }) => {
                        onCustomExpirationCheckboxClick(checked);
                      }}
                      children={[
                        <div
                          key="customExpirationDiv"
                          onClick={(e) => {
                            // Prevents parent checkbox from detecting click event on clear
                            e.preventDefault();
                          }}
                        >
                          <TextInput
                            key="customExpiration"
                            label="Expire after"
                            type="number"
                            placeholder="Enter number of periods"
                            hintText="How long the credits last after they are issued"
                            value={
                              periods !== undefined
                                ? (String(periods) as string)
                                : undefined
                            }
                            isInvalid={periods ? periods < 0 : false}
                            onChange={({ value }) => {
                              recurringCommitCtrl.update({
                                commit: {
                                  ...recurringCommitCtrl.get("commit"),
                                  commitDuration: {
                                    unit: RecurringCommitDurationUnit.Periods,
                                    value:
                                      value !== "" ? Number(value) : undefined,
                                  },
                                },
                              });
                            }}
                            fullWidth
                          />
                        </div>,
                      ]}
                    />
                    <Checkbox
                      key="customRecurringScheduleCheckbox"
                      label={`Issue ${commitOrCredit}s on a custom recurring schedule`}
                      supportingText={`Enter a custom schedule to issue ${commitOrCredit}s. If unchecked, ${commitOrCredit}s will be issued based on the usage statement frequency.`}
                      checked={isCustomRecurringChecked}
                      onClick={({ checked }) =>
                        onCustomRecurringCheckboxClick(checked)
                      }
                      children={[
                        <ButtonGroup
                          key="customRecurringScheduleButtonGroup"
                          buttons={[
                            ...Object.entries(FREQUENCY_MAPPING).map(
                              ([value, displayString]) => {
                                return {
                                  onClick: () =>
                                    recurringCommitCtrl.update({
                                      commit: {
                                        ...recurringCommitCtrl.get("commit"),
                                        recurrenceFrequency: value,
                                      },
                                    }),
                                  text: displayString,
                                  isActive:
                                    recurringCommitCtrl.get("commit")
                                      ?.recurrenceFrequency === value,
                                };
                              },
                            ),
                          ]}
                        />,
                        <div
                          key="advancedDatePickers"
                          className="flex flex-row gap-[24px]"
                          onClick={(e) => {
                            // Prevents parent checkbox from detecting click event
                            e.preventDefault();
                          }}
                        >
                          <DatePicker
                            key="customRecurringStartingAt"
                            text="Starting at"
                            value={
                              recurringStartingAt
                                ? dayjs.utc(recurringStartingAt).toDate()
                                : undefined
                            }
                            openToDate={
                              recurringStartingAt
                                ? dayjs.utc(recurringStartingAt).toDate()
                                : undefined
                            }
                            onDateApply={(date) => {
                              recurringCommitCtrl.update({
                                commit: {
                                  ...recurringCommitCtrl.get("commit"),
                                  startingAt: date?.toISOString(),
                                },
                              });
                            }}
                          />
                          <DatePicker
                            key="customRecurringEndingBefore"
                            text="Ending before"
                            value={
                              recurringEndingBefore
                                ? dayjs.utc(recurringEndingBefore).toDate()
                                : undefined
                            }
                            openToDate={
                              recurringEndingBefore
                                ? dayjs.utc(recurringEndingBefore).toDate()
                                : undefined
                            }
                            onDateApply={(date) => {
                              recurringCommitCtrl.update({
                                commit: {
                                  ...recurringCommitCtrl.get("commit"),
                                  endingBefore: date?.toISOString(),
                                },
                              });
                            }}
                          />
                        </div>,
                      ]}
                      childrenClassName="flex flex-row items-end gap-[24px]"
                    />
                  </>
                )}
                <Checkbox
                  label="Add a contract transition rollover"
                  supportingText={`When the contract ends any remaining ${commitOrCredit} balance will expire, unless a transition rollover is defined.`}
                  checked={isRolloverChecked}
                  onClick={({ checked }) => onRolloverCheckboxClick(checked)}
                  children={[
                    <div
                      key="rolloverFractionDiv"
                      onClick={(e) => {
                        // Prevents parent checkbox from detecting click event on clear
                        e.preventDefault();
                      }}
                    >
                      <TextInput
                        key="rolloverInput"
                        label="Percent of remaining balance"
                        type="number"
                        placeholder="Enter percent to rollover"
                        hintText="Percent of the total amount granted which is eligible to be rolled over to a new contract"
                        helpText={`If this contract is renewed, up to this percent of the total ${props?.asCredit ? "credit" : "commitment"} will roll over to the new contract.`}
                        variantOptions={{
                          icon: "sale02",
                          variant: "icon",
                        }}
                        isInvalid={
                          rolloverFraction
                            ? rolloverFraction > 100 || rolloverFraction < 0
                            : false
                        }
                        value={
                          rolloverFraction !== undefined
                            ? (String(rolloverFraction) as string)
                            : undefined
                        }
                        onChange={({ value }) => {
                          onRolloverChange(value);
                        }}
                        fullWidth
                      />
                    </div>,
                  ]}
                />
              </OptionGroup>
            )}
          </div>
        </>
      )}
    </>
  );
};
