import pluralize from "pluralize";
import React from "react";
import { Badge, Headline } from "design-system";

import { DeprecatedTextSkeleton } from "components/deprecated/Skeleton";
import { getUserFacingErrorMessage } from "app/lib/errors/errorHandling";

import { TinyErrorState } from "app/lib/errors/TinyErrorState";
import { ProgressBar } from "../../../components/ProgressBar";
import { printDateRange, useNow } from "lib/date";
import { RoundedCurrency } from "app/lib/credits";
import { CommitsOverviewFragment } from "./data.graphql";
import { Commit } from "app/pages/Contracts/lib/Commit";
import { AsyncCommitUsage } from "../Usage";
import { Table, type Column } from "components/Table";
import { ContractUsageInvoiceFrequencyFragment } from "../../../lib/Contract/fragments.graphql";

interface Props {
  level?: "customer" | "contract";
  asCredit?: boolean;
  commits: Omit<
    CommitsOverviewFragment["v2_fields"]["commits_union"],
    "amendment_id"
  >;
  asyncUsage?: AsyncCommitUsage;
  shouldSortAndDisplayExpiredBadges?: boolean;
  contracts: ContractUsageInvoiceFrequencyFragment[];
}

export const CommitsOverview: React.FC<Props> = ({
  commits,
  asyncUsage,
  level = "contract",
  asCredit = false,
  shouldSortAndDisplayExpiredBadges = false,
  contracts,
}) => {
  const now = useNow();
  const commitOrCredit = asCredit ? "credit" : "commit";

  const data = shouldSortAndDisplayExpiredBadges
    ? [
        ...commits.filter((c) => !Commit.isExpired(c)),
        ...commits
          .filter((c) => Commit.isExpired(c))
          .sort((a, b) => {
            // Expired commits at the end, sorted by most recently expired
            const aEnd = Commit.getDateRange(a)?.end?.valueOf() ?? -Infinity;
            const bEnd = Commit.getDateRange(b)?.end?.valueOf() ?? -Infinity;
            return bEnd - aEnd;
          }),
      ]
    : commits;

  let columns: Column<
    CommitsOverviewFragment["v2_fields"]["commits_union"][number]
  >[] = [
    {
      id: "name",
      header: asCredit ? "Credit" : "Commit",
      accessorFn: (
        row: CommitsOverviewFragment["v2_fields"]["commits_union"][number],
      ) => row,
      cell: (props) => {
        const row = props.getValue();
        return (
          <div>
            <div className="flex items-start gap-4">
              <div>{Commit.getName(row, now)}</div>
              {shouldSortAndDisplayExpiredBadges && Commit.isExpired(row) ? (
                <Badge theme="error" type="light">
                  EXPIRED
                </Badge>
              ) : (
                ""
              )}
            </div>
            <div className="text-xxs font-normal text-gray-600">
              {Commit.printLevel(row)}-level {Commit.printType(row)}
            </div>
          </div>
        );
      },
      enableSorting: false,
    },
  ];
  columns = [
    ...columns,
    {
      id: "priority",
      header: "Priority",
      accessorFn: (
        row: CommitsOverviewFragment["v2_fields"]["commits_union"][number],
      ) => row,
      cell: (props) => {
        const row = props.getValue();
        return <div>{row.priority}</div>;
      },
      enableSorting: false,
    },
    {
      id: "accessSchedule",
      header: "Access schedule",
      accessorFn: (
        row: CommitsOverviewFragment["v2_fields"]["commits_union"][number],
      ) => row,
      cell: (props) => {
        const row = props.getValue();
        const dates = Commit.getDateRange(row);
        if (!(dates?.start && dates.end)) {
          return "--";
        }
        return (
          <div>
            <div>
              {printDateRange(now, dates.start, dates.end)}
              {row.access_schedule.schedule_items.length > 1 ? (
                <div className="text-xxs font-normal text-deprecated-gray-medium">
                  {row.access_schedule.schedule_items.length} segments
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="text-xxs font-normal text-gray-600">
              {Commit.printRecurringAccessSchedule(row, contracts)}
            </div>
          </div>
        );
      },
      enableSorting: false,
    },
  ];
  if (!asCredit) {
    columns = [
      ...columns,
      {
        id: "invoiceSchedule",
        header: "Invoice schedule",
        accessorFn: (
          row: CommitsOverviewFragment["v2_fields"]["commits_union"][number],
        ) => row,
        cell: (props) => {
          const row = props.getValue();
          const count = Commit.getInvoiceCount(row);
          return (
            <div>
              {count != null ? pluralize("invoice", count, true) : "--"}
            </div>
          );
        },
        enableSorting: false,
      },
      {
        id: "totalInvoiced",
        header: "Total invoiced",
        accessorFn: (
          row: CommitsOverviewFragment["v2_fields"]["commits_union"][number],
        ) => row,
        cell: (props) => {
          const row = props.getValue();
          return <div>{Commit.renderTotalCost(row)}</div>;
        },
        align: "right",
        enableSorting: false,
      },
    ];
  }
  columns = [
    ...columns,
    {
      id: "consumed",
      header: `Consumed / Total ${asCredit ? "credit" : "commit"}`,
      align: "right",
      accessorFn: (
        row: CommitsOverviewFragment["v2_fields"]["commits_union"][number],
      ) => row,
      cell: (props) => {
        const row = props.getValue();
        if (!asyncUsage) {
          return null;
        }

        if (asyncUsage.loading) {
          return (
            <div className="flex flex-col">
              <DeprecatedTextSkeleton width="66%" className="self-end" />
              <DeprecatedTextSkeleton width="66%" className="self-end" />
            </div>
          );
        }

        if (asyncUsage.error) {
          const msg = getUserFacingErrorMessage(asyncUsage.error);
          return <TinyErrorState message={`Unable to load usage: ${msg}`} />;
        }

        const usage = asyncUsage.forCommit(row.id);
        if (!usage) {
          return null;
        }

        return (
          <div className="grid grid-rows-2 items-center justify-items-end">
            <div>
              <RoundedCurrency
                amount={usage.used}
                creditType={row.access_schedule.credit_type}
                hideSuffix
              />{" "}
              / {Commit.renderTotalAmount(row)}
            </div>
            <div className="w-[120px]">
              <ProgressBar
                progress={usage.used}
                total={usage.total}
                progressColor="bg-core-deep-denim"
              />
            </div>
          </div>
        );
      },
      enableSorting: false,
    },
  ];

  return (
    <Table
      title={asCredit ? "Credits" : "Commits"}
      data={data}
      rowRoutePath={(row) => {
        return Commit.getRoutePath(row.original);
      }}
      emptyState={
        <div className="p-12 text-center">
          <Headline level={6} className="text-gray-400">
            {`This ${level} has no ${commitOrCredit}s.`}
          </Headline>
        </div>
      }
      columns={columns}
    />
  );
};
