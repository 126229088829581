import React from "react";
import { Select, Icon, Subtitle, ButtonDropdown } from "design-system";

import { CustomerContractLayout } from "../CustomerContractLayout";
import { useNow, toDayjs, printDateRange, printDate, dayjs } from "lib/date";
import { useRequiredParam } from "app/lib/routes/params";
import { useApolloResp } from "app/pages/Contracts/lib/ApolloResp";
import {
  ContractMetadataFragment,
  ContractOverviewQuery,
  DiscountTermFlyoverFragment,
  ProServiceTermFlyoverFragment,
  ScheduledChargeTermFlyoverFragment,
  useContractOverviewQuery,
} from "../Overview/data.graphql";
import {
  ResellerRoyaltyState,
  getActivityItems,
  getName,
  getRoutePath,
} from "app/pages/Contracts/lib/Contract/Contract";
import { useParams } from "react-router-dom";
import { useNavigate } from "app/lib/useNavigate";
import { DeprecatedInternalLink } from "components/deprecated/Typography";
import { DeprecatedPanel } from "components/deprecated/Panel";
import { DeprecatedDefinitionList } from "components/deprecated/DefinitionList";
import { ContractStatusBadge } from "../components/ContractStatusBadge";
import { CustomerContractOverview } from "../Overview";
import { ActivityItem } from "app/pages/Contracts/components/ActivityList";
import { CommitsOverview } from "../Overview/CommitsOverview";
import { useAsyncCommitUsage } from "../Usage";
import { AdditionalTermsTable } from "../../../components/AdditionalTermsTable";
import {
  getAmendmentTerms,
  getRowsFromTerms,
} from "../Overview/AdditionalTermRows";
import { ContractProductFlyover } from "app/pages/Contracts/Pricing/ContractProductFlyover";
import {
  ResellerRoyaltyTermFlyover,
  DiscountTermFlyover,
  ScheduledChargeTermFlyover,
  ProServiceTermFlyover,
} from "../Overview/ContractTermFlyover";
import { DeprecatedCopyableID } from "components/deprecated/CopyableID";
import { ContractMetadataFlyover } from "../Overview/ContractMetadataFlyover";
import { RateSchedulePanel } from "app/pages/Contracts/components/RateSchedulePanel";
import { describeOverride } from "app/pages/Contracts/lib/Override";
import { Commit } from "app/pages/Contracts/lib/Commit";
import { Contract } from "app/pages/Contracts/lib/Contract";

type Contract = Exclude<
  Exclude<ContractOverviewQuery["customer"], null>["contract"],
  null
>;

export const CustomerContractLifecycle: React.FC = () => {
  const navigate = useNavigate();
  const contractId = useRequiredParam("contractId");
  const customerId = useRequiredParam("customerId");
  const req = useApolloResp(
    useContractOverviewQuery({
      variables: {
        contractId,
        customerId,
      },
    }),
  );
  const now = useNow();
  const lifecycleId = useParams<{ lifecycleId: string }>().lifecycleId;

  function setActiveLifecycleId(lifecycleId: string) {
    navigate(
      `/customers/${customerId}/contracts/${contractId}/lifecycle/${lifecycleId}`,
    );
  }

  return (
    <CustomerContractLayout
      rootReq={req}
      content={({ contract }) => {
        const activityItems = getActivityItems(contract, now, true);

        const getItemDisplay = (item: ActivityItem) => {
          const capitalizedType = `${item.type
            .charAt(0)
            .toUpperCase()}${item.type.slice(1)}`;
          return `${printDate(item.time)}: ${capitalizedType}`;
        };

        const currentItem = lifecycleId
          ? activityItems.find(({ id }) => id === lifecycleId)
          : activityItems[0];

        if (!currentItem) return null;

        const amendment = contract.amendments.find(
          (a) => a.id === currentItem.id,
        );

        return (
          <div className="mt-12 flex flex-col gap-12">
            <div className="flex items-center">
              <div className="flex items-center gap-4">
                <Subtitle level={1} className="font-medium">
                  {getItemDisplay(currentItem)}
                </Subtitle>
              </div>
              <Select
                className="ml-auto w-[240px]"
                placeholder="Select a lifecycle event"
                options={activityItems.map((item) => ({
                  value: item.id,
                  label: getItemDisplay(item),
                }))}
                value={currentItem.id}
                onChange={setActiveLifecycleId}
              />
            </div>
            {currentItem.type === "start" && (
              <CustomerContractOverview
                contract={contract}
                customerId={customerId}
              />
            )}
            {currentItem.type === "end" && (
              <ContractEnded contract={contract} />
            )}
            {currentItem.type === "amendment" && amendment && (
              <ContractAmended
                customerId={customerId}
                contract={contract}
                amendment={amendment}
              />
            )}
          </div>
        );
      }}
    />
  );
};

function ContractAmended<C extends Contract>({
  customerId,
  contract,
  amendment,
}: {
  customerId: string;
  contract: C;
  amendment: C["amendments"][number];
}) {
  const usage = useAsyncCommitUsage({
    customerId,
    contractId: contract.id,
  });
  const now = useNow();

  const [flyoverTarget, setFlyoverTarget] = React.useState<
    | { type: "product"; id: string }
    | { type: "discount"; term: DiscountTermFlyoverFragment }
    | { type: "scheduled_charge"; term: ScheduledChargeTermFlyoverFragment }
    | { type: "pro_service"; term: ProServiceTermFlyoverFragment }
    | { type: "reseller_royalty"; term: ResellerRoyaltyState }
    | { type: "contract"; term: ContractMetadataFragment }
  >();

  const termRows = getRowsFromTerms(
    getAmendmentTerms(amendment),
    now,
    (term) => {
      switch (term.__typename) {
        case "Discount":
          setFlyoverTarget({ type: "discount", term });
          break;
        case "ScheduledCharge":
          setFlyoverTarget({ type: "scheduled_charge", term });
          break;
        case "ProService":
          setFlyoverTarget({ type: "pro_service", term });
          break;
        case "AWSRoyalty":
        case "AWSProServiceRoyalty":
        case "GCPRoyalty":
        case "GCPProServiceRoyalty":
          setFlyoverTarget({ type: "reseller_royalty", term });
          break;
        case "AWSRoyaltyUpdate":
        case "AWSProServiceRoyaltyUpdate":
        case "GCPRoyaltyUpdate":
        case "GCPProServiceRoyaltyUpdate":
          return;
        default:
          term satisfies never;
      }
    },
  );

  const { commits, credits } = Commit.splitCommitsAndCredits(
    amendment.commits_union,
  );

  const amendmentOverrideIndex = contract.v2_fields?.overrides.findLastIndex(
    (o) => o.amendment_id === amendment.id,
  );
  const overridesUntilAmendment =
    amendmentOverrideIndex !== -1
      ? contract.v2_fields?.overrides.slice(0, amendmentOverrideIndex + 1)
      : undefined;

  return (
    <>
      {/* Flyover */}
      {((): React.ReactElement | null => {
        if (!flyoverTarget) {
          return null;
        }

        const onRequestClose = () => setFlyoverTarget(undefined);

        switch (flyoverTarget.type) {
          case "product":
            return (
              <ContractProductFlyover
                productId={flyoverTarget.id}
                onRequestClose={onRequestClose}
              />
            );
          case "discount":
            return (
              <DiscountTermFlyover
                term={flyoverTarget.term}
                onRequestClose={onRequestClose}
              />
            );
          case "scheduled_charge":
            return (
              <ScheduledChargeTermFlyover
                term={flyoverTarget.term}
                onRequestClose={onRequestClose}
              />
            );
          case "pro_service":
            return (
              <ProServiceTermFlyover
                term={flyoverTarget.term}
                onRequestClose={onRequestClose}
              />
            );
          case "reseller_royalty":
            return (
              <ResellerRoyaltyTermFlyover
                term={flyoverTarget.term}
                onRequestClose={onRequestClose}
              />
            );
          case "contract":
            return (
              <ContractMetadataFlyover
                contract={flyoverTarget.term}
                onRequestClose={onRequestClose}
                contractName={`${printDate(
                  dayjs(amendment.effective_at),
                )}: Amendment`}
                amendmentId={amendment.id}
              />
            );
        }
      })()}

      <DeprecatedPanel
        title="Amendment details"
        controls={
          <ButtonDropdown
            buttonTheme="gray"
            buttonType="fill"
            icon="ellipsisVertical"
            items={[
              {
                label: "View metadata",
                onClick: () =>
                  setFlyoverTarget({ type: "contract", term: contract }),
              },
            ]}
          />
        }
      >
        <div className="flex gap-32 p-12">
          <DeprecatedDefinitionList
            list={[
              {
                label: "Amendment ID",
                value: (
                  <DeprecatedCopyableID
                    id={amendment.id}
                    label="amendment ID"
                  />
                ),
              },
              {
                label: "Amendment start",
                className: "mr-24",
                value: printDate(dayjs(amendment.effective_at)),
              },
            ]}
          />
        </div>
      </DeprecatedPanel>

      {commits.length > 0 && (
        <CommitsOverview
          commits={commits}
          asyncUsage={usage}
          contracts={[contract]}
        />
      )}

      {credits.length > 0 && (
        <CommitsOverview
          commits={credits}
          asyncUsage={usage}
          asCredit
          contracts={[contract]}
        />
      )}

      {termRows.length > 0 && (
        <AdditionalTermsTable rows={termRows} renderT10Table />
      )}

      {overridesUntilAmendment && contract.rate_card && (
        <RateSchedulePanel
          title={contract.rate_card.name}
          startingAt={new Date(amendment.effective_at)}
          endingBefore={
            contract.ending_before ? new Date(contract.ending_before) : null
          }
          rateCardId={contract.rate_card.id}
          overrides={overridesUntilAmendment.map((o) =>
            describeOverride(now, o),
          )}
          onRowClick={(row) => {
            setFlyoverTarget({ type: "product", id: row.product.id });
          }}
          renderT10Table
        />
      )}
    </>
  );
}

function ContractEnded({ contract }: { contract: Contract }) {
  const now = useNow();

  const transition = Contract.getTransition(contract);

  return (
    <DeprecatedPanel title="Contract details">
      <div className="flex p-12">
        <DeprecatedDefinitionList
          list={[
            {
              label: "Contract length",
              className: "mr-24",
              value: printDateRange(
                now,
                toDayjs(contract.starting_at),
                contract.ending_before
                  ? toDayjs(contract.ending_before)
                  : undefined,
              ),
              badge: <ContractStatusBadge contract={contract} />,
            },
            ...(!!transition
              ? [
                  {
                    label: "Next contract",
                    value: (
                      <DeprecatedInternalLink
                        routePath={getRoutePath(transition.to_contract)}
                      >
                        {getName(transition.to_contract)}
                        <Icon
                          icon="open"
                          className="ml-4 text-deprecated-primary-500"
                        />
                      </DeprecatedInternalLink>
                    ),
                  },
                ]
              : []),
          ]}
        />
      </div>
    </DeprecatedPanel>
  );
}
