import React from "react";
import { Copy } from "../Copy";
import { Button } from "../Button";
import { twMerge } from "tenaissance/twMerge";

type CopyableIDProps = {
  id: string;
  className?: string;
};

export const CopyableID: React.FC<CopyableIDProps> = ({ id, className }) => {
  return (
    <Copy text={id}>
      <Button
        text={id}
        leadingIcon="copy01"
        theme="linkGray"
        className={twMerge("font-normal", className)}
      />
    </Copy>
  );
};
