import React, { useContext } from "react";
import { CommitFlyoverController } from "./CommitFlyoverController";
import { SectionHeader } from "components/SectionHeader";
import { Card } from "components/Card";
import { Badge } from "components/Badge";
import { BadgeGroup } from "components/BadgeGroup";
import { dayjs } from "lib/dayjs";
import { findCreditType } from "app/pages/Contracts/lib/CreditTypes";
import {
  USD_CREDIT_ID,
  displayCreditsInCurrencyWithoutRounding,
} from "app/lib/credits";
import { CreditTypeContext, RecurringCommitController } from ".";
import Decimal from "decimal.js";
import {
  CommitType,
  ContractUsageInvoiceScheduleFrequencyEnum,
  RecurringCommitProration,
} from "types/generated-graphql/__types__";
import { RecurringSchedule } from "@metronome-industries/schedule-utils";
import { Schema } from "../../Schema";
import { PRORATION_MAPPING } from "./RecurringCommitTerms";
import { FREQUENCY_MAPPING } from "./CommitAdvancedTerms";

const DATE_FORMAT_STRING = "MMMM DD, YYYY";
// Maps recurrence frequency to user friendly strings to display with expiration
const EXPIRATION_FREQUENCY_MAPPING: Record<
  ContractUsageInvoiceScheduleFrequencyEnum,
  string
> = {
  [ContractUsageInvoiceScheduleFrequencyEnum.Weekly]: "week",
  [ContractUsageInvoiceScheduleFrequencyEnum.Monthly]: "month",
  [ContractUsageInvoiceScheduleFrequencyEnum.Quarterly]: "quarter",
  [ContractUsageInvoiceScheduleFrequencyEnum.Annual]: "year",
};

export const SingleCommitPreview: React.FC<{
  ctrl: CommitFlyoverController;
  level: "contract" | "customer";
  asCredit: boolean;
}> = ({ ctrl, asCredit }) => {
  const creditTypeContext = useContext(CreditTypeContext);
  const commit = ctrl.get("commit");

  const accessSchedule = commit?.accessSchedule;
  const accessScheduleCreditType = findCreditType(
    commit?.accessScheduleCreditTypeId ?? USD_CREDIT_ID,
    [
      ...creditTypeContext.fiatCreditTypes,
      ...creditTypeContext.customCreditTypes,
      creditTypeContext.rateCardFiatCreditType,
    ],
  );
  const { totalAccessAmount, earliestStartingAt, latestEndingBefore } = (() => {
    if (accessSchedule && accessSchedule.length > 0) {
      const totalAccessAmount = accessSchedule.reduce(
        (sum, item) => sum + item.amount,
        0,
      );

      const validDates = accessSchedule
        .map((item) => item.date)
        .filter((date) => date !== undefined);
      // Get the earliest starting at date, or set to undefined if all dates are undefined
      const earliestStartingAt =
        validDates.length > 0
          ? validDates.reduce((earliest, startingAt) =>
              new Date(startingAt).getTime() < new Date(earliest).getTime()
                ? startingAt
                : earliest,
            )
          : undefined;

      const validEndDates = accessSchedule
        .map((item) => item.endDate)
        .filter((endDate) => endDate !== undefined);
      // Get the earliest ending before date, or set to undefined if all endDates are undefined
      const latestEndingBefore =
        validEndDates.length > 0
          ? validEndDates.reduce((latest, endingBefore) =>
              new Date(endingBefore).getTime() > new Date(latest).getTime()
                ? endingBefore
                : latest,
            )
          : undefined;

      return { totalAccessAmount, earliestStartingAt, latestEndingBefore };
    }
    return {
      totalAccessAmount: undefined,
      earliestStartingAt: undefined,
      latestEndingBefore: undefined,
    };
  })();

  const amountString = totalAccessAmount
    ? `${displayCreditsInCurrencyWithoutRounding(
        new Decimal(totalAccessAmount),
        accessScheduleCreditType,
      )} ${asCredit ? "total" : "total balance"}`
    : "--";
  const dateString = earliestStartingAt
    ? dayjs.utc(earliestStartingAt).format(DATE_FORMAT_STRING)
    : undefined;
  const endDateString = latestEndingBefore
    ? `- ${dayjs.utc(latestEndingBefore).format(DATE_FORMAT_STRING)}`
    : "onward";

  const billingScheduleCreditTypeId =
    (commit && commit.type === CommitType.Prepaid
      ? commit.billingScheduleCreditTypeId
      : undefined) ??
    commit?.accessScheduleCreditTypeId ??
    USD_CREDIT_ID;
  const billingScheduleCreditType = findCreditType(
    billingScheduleCreditTypeId,
    [
      ...creditTypeContext.fiatCreditTypes,
      ...creditTypeContext.customCreditTypes,
      creditTypeContext.rateCardFiatCreditType,
    ],
  );
  const totalInvoiceCost = (() => {
    if (commit?.billingSchedule) {
      if (commit.type === CommitType.Postpaid) {
        return commit.billingSchedule.reduce((total, item) => {
          if (item.quantity === undefined || item.unitPrice === undefined) {
            return total;
          }

          return total.plus(new Decimal(item.quantity).mul(item.unitPrice));
        }, new Decimal(0));
      }
      switch (commit.billingSchedule.type) {
        case "fixed":
          return commit.billingSchedule.items.reduce((total, item) => {
            if (item.quantity === undefined || item.unitPrice === undefined) {
              return total;
            }
            return total.plus(new Decimal(item.quantity).mul(item.unitPrice));
          }, new Decimal(0));
        case "recurring":
          if (
            commit.billingSchedule.unitPrice === undefined ||
            commit.billingSchedule.quantity === undefined ||
            commit.billingSchedule.startDate === undefined ||
            commit.billingSchedule.endDate === undefined ||
            commit.billingSchedule.frequency === undefined ||
            commit.billingSchedule.amountDistribution === undefined
          ) {
            return new Decimal(0);
          }

          const scheduleItems = RecurringSchedule.resolve({
            startDate: new Date(commit.billingSchedule.startDate),
            endDate: new Date(commit.billingSchedule.endDate),
            frequency: commit.billingSchedule.frequency,
            amountDistribution: commit.billingSchedule.amountDistribution,
            unitPrice: new Decimal(commit.billingSchedule.unitPrice),
            quantity: new Decimal(commit.billingSchedule.quantity),
          }).items;
          return scheduleItems.reduce((total, item) => {
            if (item.amount === undefined) {
              return total;
            }
            return total.plus(item.amount);
          }, new Decimal(0));
      }
    }
    return undefined;
  })();

  // Gets the billing date if there is a single invoice, otherwise undefined (use MultiInvoicePreview instead)
  const billingDate = (() => {
    if (commit?.billingSchedule) {
      if (commit.type === CommitType.Postpaid) {
        if (commit.billingSchedule.length > 0) {
          return commit.billingSchedule[0].date;
        }
        return undefined;
      }
      switch (commit.billingSchedule.type) {
        case "fixed":
          if (commit.billingSchedule.items.length == 1) {
            return commit.billingSchedule.items[0].date;
          }
          return undefined;
        case "recurring":
          return undefined;
      }
    }
    return undefined;
  })();

  return (
    <div className="flex flex-col gap-[24px]">
      <div>
        <p className="text-2xl font-semibold">{amountString}</p>
        {dateString && (
          <p className="text-sm font-medium text-gray-600">{`Effective at: ${dateString} ${endDateString}`}</p>
        )}
      </div>
      <div>
        {!asCredit && totalInvoiceCost && (
          <>
            <p className="text-2xl font-semibold">{`${displayCreditsInCurrencyWithoutRounding(
              new Decimal(totalInvoiceCost),
              billingScheduleCreditType,
            )} total invoiced`}</p>
            {billingDate && (
              <p className="text-sm font-medium">{`Effective at: ${dayjs
                .utc(billingDate)
                .format(DATE_FORMAT_STRING)}`}</p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const RecurringCommitAmount: React.FC<{
  commit?: Schema.Types.RecurringCommitRoot["commit"];
  amountDetails:
    | Schema.Types.RecurringCommitRoot["commit"]["accessAmount"]
    | Schema.Types.RecurringCommitRoot["commit"]["invoiceAmount"];
  amountType: "balance" | "invoice";
  asCredit: boolean;
}> = ({ commit, amountDetails, amountType, asCredit }) => {
  const creditTypeContext = useContext(CreditTypeContext);
  const frequency = commit?.recurrenceFrequency;

  const creditTypeId = findCreditType(
    amountDetails?.creditTypeId ?? USD_CREDIT_ID,
    [
      ...creditTypeContext.fiatCreditTypes,
      ...creditTypeContext.customCreditTypes,
      creditTypeContext.rateCardFiatCreditType,
    ],
  );
  const amountDisplayString =
    amountDetails?.unitPrice && amountDetails.quantity
      ? `${displayCreditsInCurrencyWithoutRounding(
          new Decimal(amountDetails?.unitPrice * amountDetails.quantity),
          creditTypeId,
        )} ${frequency ? FREQUENCY_MAPPING[frequency].toLowerCase() : ""} ${!asCredit ? amountType : ""}`
      : "--";

  const proration = commit?.proration
    ? PRORATION_MAPPING[commit?.proration]
    : "";
  const prorationDisplayString = commit?.proration
    ? commit?.proration === RecurringCommitProration.None
      ? `(${proration})`
      : `(${proration} issue)`
    : "";

  const dateString = commit?.startingAt
    ? dayjs.utc(commit?.startingAt).format(DATE_FORMAT_STRING)
    : undefined;
  const endDateString = commit?.endingBefore
    ? `- ${dayjs.utc(commit?.endingBefore).format(DATE_FORMAT_STRING)}`
    : "onward";

  return (
    amountDetails && (
      <div>
        <div className="flex flex-row items-center">
          <p className="mr-8 text-2xl font-semibold">{amountDisplayString}</p>
          {amountType === "balance" && (
            <p className="text-lg font-medium">{prorationDisplayString}</p>
          )}
        </div>
        {dateString && (
          <p className="text-sm font-medium text-gray-600">{`Effective at: ${dateString} ${endDateString}`}</p>
        )}
      </div>
    )
  );
};

export const RecurringCommitPreview: React.FC<{
  ctrl: RecurringCommitController;
  asCredit: boolean;
}> = ({ ctrl, asCredit }) => {
  const commit = ctrl.get("commit");

  return (
    <div className="flex flex-col gap-[24px]">
      <RecurringCommitAmount
        commit={commit}
        amountDetails={commit?.accessAmount}
        amountType="balance"
        asCredit={asCredit}
      />
      {!asCredit && (
        <RecurringCommitAmount
          commit={commit}
          amountDetails={commit?.invoiceAmount}
          amountType="invoice"
          asCredit={asCredit}
        />
      )}
    </div>
  );
};

export const CommitPreview: React.FC<{
  ctrl: CommitFlyoverController | RecurringCommitController;
  isRecurring: boolean;
  level: "contract" | "customer";
  asCredit: boolean;
  productName?: string;
  applicableProductNames?: string[];
}> = ({
  ctrl,
  isRecurring,
  level,
  asCredit,
  productName,
  applicableProductNames,
}) => {
  const name = ctrl.get("name") ?? productName ?? "--";
  const description = ctrl.get("description");
  const commit = ctrl.get("commit");
  const priority = commit?.priority;
  const rolloverFraction = commit?.rolloverFraction;
  const tags = ctrl.get("applicableProductTags");

  let periods = undefined;
  let frequencyDisplayString = undefined;
  if (isRecurring) {
    const commitDuration = (
      commit as Schema.Types.RecurringCommitRoot["commit"]
    )?.commitDuration;
    periods = commitDuration?.value;
    const frequency = (commit as Schema.Types.RecurringCommitRoot["commit"])
      ?.recurrenceFrequency;
    frequencyDisplayString = frequency
      ? periods > 1
        ? `${EXPIRATION_FREQUENCY_MAPPING[frequency]}s`
        : EXPIRATION_FREQUENCY_MAPPING[frequency]
      : "";
  }
  return (
    <>
      <SectionHeader title="Preview" subtitle="" />
      <Card className="w-full">
        <div className="flex justify-between">
          <div className="flex-col">
            <p className="text-md font-bold text-gray-600">{name}</p>
            {description && (
              <p className="text-xs text-gray-600">{description}</p>
            )}
          </div>
          <div className="float-right flex flex-row-reverse justify-between gap-lg">
            {priority !== undefined && <Badge label={`Priority ${priority}`} />}
            {rolloverFraction !== undefined && (
              <Badge
                label={`Rollover up to ${rolloverFraction}% to next contract`}
              />
            )}
            {applicableProductNames && applicableProductNames.length > 0 && (
              <BadgeGroup
                mainLabel={applicableProductNames.join(", ")}
                badgeLabel="Applicable Products"
              />
            )}
            {tags && tags.length > 0 && (
              <BadgeGroup
                mainLabel={tags.join(", ")}
                badgeLabel="Applicable Tags"
              />
            )}
            {periods && (
              <Badge
                label={`Expire after ${periods} ${frequencyDisplayString ?? ""}`}
              />
            )}
          </div>
        </div>
        {!isRecurring ? (
          <SingleCommitPreview
            ctrl={ctrl as CommitFlyoverController}
            level={level}
            asCredit={asCredit}
          />
        ) : (
          <RecurringCommitPreview
            ctrl={ctrl as RecurringCommitController}
            asCredit={asCredit}
          />
        )}
      </Card>
    </>
  );
};
